import { Link } from "gatsby"
import { default as React } from "react"
import {
  connectStateResults,
  Highlight,
  Hits,
  Index,
  Snippet,
  PoweredBy,
} from "react-instantsearch-dom"
import { css, ThemeProvider, useTheme } from "@emotion/react"
import {
  colorPrimatives,
  fontFamilies,
  fontSizes,
  defaultTheme,
} from "../../styles/theme"
import Text from "../../styles/typography"

const HitCount = connectStateResults(({ searchResults, onClose }) => {
  const hitCount = searchResults && searchResults.nbHits

  return (
    <Text
      css={css`
        text-align: right;
        color: ${colorPrimatives.tumbleweed};
        margin: 0 1rem;
        font-family: ${fontFamilies.tajawal};
      `}
    >
      {hitCount > 0
        ? `${hitCount} result${hitCount !== 1 ? `s` : ``}`
        : "No results"}
    </Text>
  )
})

const PageHit =
  onClose =>
  ({ hit }) => {
    const theme = useTheme()

    return (
      <>
        <Text
          el={Link}
          to={hit.slug}
          onClick={onClose}
          css={css`
            font-size: ${fontSizes.md};
            text-decoration: none;
            font-family: ${fontFamilies.tajawal};

            :hover {
              color: ${theme.color.link};
            }
            ::before {
              content: "";
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
            }
          `}
        >
          <Highlight attribute="title" hit={hit} tagName="mark" />
        </Text>
        <Text
          css={css`
            margin-top: 0.5rem;
          `}
        >
          <Snippet attribute="excerpt" hit={hit} tagName="mark" />
        </Text>
        <Text
          el=""
          css={css`
            margin-top: 0.5rem;
            text-align: right;
            font-weight: bold;
            color: ${theme.color.link};
          `}
        >
          <Highlight attribute="tags" hit={hit} tagName="mark" />
        </Text>
        <hr
          css={css`
            margin: 1rem 0 0;
          `}
        />
      </>
    )
  }

const HitsInIndex = ({ index, onClose }) => {
  const theme = useTheme()

  return (
    <Index indexName={index.name}>
      <HitCount />
      <hr
        css={css`
          margin: 1rem 0 0;
        `}
      />
      <Hits
        css={css`
          ul {
            list-style: none;
            margin-left: 0;
          }
          li.ais-Hits-item {
            position: relative;
            padding: 2rem 2rem 0;
            :hover {
              cursor: pointer;
              background: ${theme.color.background};
            }
          }
          mark {
            background: ${theme.color.buttonPrimary};
            color: ${theme.color.buttonTextPrimary};
          }
        `}
        hitComponent={PageHit(onClose)}
      />
    </Index>
  )
}

const SearchResult = ({ indices, show, onClose }) => {
  const theme = useTheme()

  return (
    <div
      css={css`
        display: ${show ? "block" : "none"};
        max-height: 400px;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        position: absolute;
        z-index: 2;
        left: 0;
        right: 0;
        top: 100%;
        box-shadow: 0 0 5px 0;
        padding: 1rem 0;
        border-radius: 2px;
        background: ${colorPrimatives.overlay};

        .ais-PoweredBy {
          background: ${colorPrimatives.mistyRose};
          opacity: 0.5;
          padding: 0.5rem 1rem;
          border-radius: 2rem;
          margin: 1rem auto 0;
          width: max-content;
          display: flex;
        }
      `}
    >
      {indices.map(index => (
        <HitsInIndex index={index} key={index.name} onClose={onClose} />
      ))}
      <PoweredBy />
    </div>
  )
}

export default SearchResult
