import { css, useTheme } from "@emotion/react"
import texture from "../images/texture.jpg"
import { breakpoints, mediaQueries } from "../styles/theme"

const Main = ({ children }) => {
  const theme = useTheme()

  return (
    <main
      css={css`
        background-color: ${theme.color.background};
        background-image: url(${texture});
        background-repeat: repeat;
        background-size: auto;
        background-blend-mode: luminosity;
        background-position: top center;
      `}
    >
      <div
        css={css`
          background-color: ${theme.color.background};
          max-width: ${breakpoints.container + 128}px;
          margin: auto;
          ${mediaQueries.md} {
            padding: 0 32px;
          }
        `}
      >
        {children}
      </div>
    </main>
  )
}

export default Main
