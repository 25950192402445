import React from "react"
import Twitter from "./Icons/twitter"
import Linkedin from "./Icons/linkedin"
import Rssfeed from "./Icons/rss-feed"
import { css } from "@emotion/react"
import Text from "../styles/typography"
import { Link } from "gatsby"
import config from "../../data/SiteConfig"

function UserLinks({ config, labeled }) {
  function getLinkElements() {
    const { userLinks } = config

    const iconStyles = css`
      margin: 0 16px;
      height: 1.2rem;
    `

    return (
      <section
        css={css`
          display: flex;
          justify-content: center;
          align-items: flex-end;
        `}
      >
        <Text
          el="a"
          href="https://twitter.com/Ali_Rehmatullah"
          title="Follow me on Twitter"
        >
          <Twitter css={iconStyles}></Twitter>
        </Text>
        <Text
          el="a"
          href="https://www.linkedin.com/in/ali-rehmatullah/"
          title="Connect with me on LinkedIn"
        >
          <Linkedin css={iconStyles}></Linkedin>
        </Text>
        <Text el={Link} to={config.siteRss} title="Subscribe to my RSS feed">
          <Rssfeed css={iconStyles}></Rssfeed>
        </Text>
      </section>
    )
  }

  const { userLinks } = config
  if (!userLinks) {
    return null
  }
  return <div className="user-links">{getLinkElements()}</div>
}

export default UserLinks
