import { css, useTheme, keyframes } from "@emotion/react"
import SearchIcon from "../Icons/search"
import CloseIcon from "../Icons/close"
import { fontSizes, mediaQueries, fontFamilies } from "../../styles/theme"
import { IconButton } from "../Button"
import { connectSearchBox } from "react-instantsearch-dom"
import { useCallback, useEffect } from "react"

const SearchBar = ({
  onClose,
  refine,
  currentRefinement,
  onFocus,
  hasFocus,
}) => {
  const keydown = useCallback()

  useEffect(() => {
    function keydown({ code }) {
      if (code === "Escape") {
        if (currentRefinement === "") {
          // alert(currentRefinement)
          onClose()
        } else {
          refine("")
        }
      }
    }
    window.addEventListener("keydown", keydown)
    return () => {
      console.log("unmounted event listener")
      window.removeEventListener("keydown", keydown)
    }
  }, [currentRefinement, refine])

  const theme = useTheme()

  const expandAnimation = keyframes`
		from {
			transform: scaleX(0);
		}
		to {
			transform: scaleX(100%);
		}
	`

  const slideIn = keyframes`
		from {
			transform: translate3d(calc(100% - 1.5rem), 0, 0);
		}
		to {
			transform: translate3d(0, 0, 0);
		}
	`

  const expandIn = keyframes`
		from {
			transform: scale(0);
		}
		to {
			transform: scale(100%);
		}
	`

  const transitionDuration = "150ms"
  const transitionTimingFunction = "ease"

  return (
    <div
      css={css`
        position: absolute;
        top: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        background: ${theme.color.background};
        font-family: ${fontFamilies.tajawal};
        animation: ${slideIn} ${transitionDuration} ${transitionTimingFunction};
      `}
    >
      <SearchIcon
        focusable="false"
        css={css`
          ${mediaQueries.sm} {
            height: ${fontSizes.md};
          }
          fill: ${theme.color.text};
        `}
      />

      <input
        type="text"
        name="Search"
        id=""
        placeholder="Search..."
        aria-label="Search"
        onChange={e => refine(e.target.value)}
        value={currentRefinement}
        onFocus={onFocus}
        autoFocus
        autocomplete="off"
        css={css`
          margin: 0 8px;
          width: 100%;
          border: none;
          border-bottom: 1px solid ${theme.color.text};
          background-color: transparent;
          color: ${theme.color.text};

          ${mediaQueries.sm} {
            font-size: ${fontSizes.md};
          }

          transform-origin: left;
          animation: ${expandAnimation} ${transitionDuration}
            ${transitionTimingFunction};

          &:focus {
            outline: none;
          }
        `}
      />
      <IconButton
        onClick={onClose}
        icon={
          <CloseIcon
            css={css`
              ${mediaQueries.sm} {
                height: ${fontSizes.md};
              }

              transform: scale(0);
              animation: ${expandIn} 150ms ${transitionDuration} ease forwards;
            `}
          />
        }
      />
    </div>
  )
}

export default connectSearchBox(SearchBar)
