import React from "react"
import { css, useTheme } from "@emotion/react"
import { Link } from "gatsby"
import { fontSizes, mediaQueries, colorPrimatives } from "./theme"

const Text = ({ el, children, css: cssProp, ...forwardProps }) => {
  const validElements = [
    "a",
    "p",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    // "ol",
    // "ul",
    "li",
    "blockquote",
    "q",
    "figcaption",
    Link,
  ]

  const El = validElements.find(validElement => validElement === el) || "p"

  const isLink = ["a", Link].includes(El)

  const theme = useTheme()
  return (
    <El
      {...forwardProps}
      css={css`
        color: ${isLink
          ? theme.color.link
          : El === "figcaption"
          ? theme.color.quote
          : theme.color.text};

        ${isLink &&
        `
          fill: ${theme.color.text};
        `}

        ${isLink &&
        css`
          font-weight: 700;
          text-decoration: underline;
          &:hover {
            color: ${theme.color.linkHover};
            fill: ${colorPrimatives.tumbleweed};
          }
        `}

        ${El === "figcaption" &&
        css`
          text-align: center;
          font-style: italic;
          padding-top: 1rem;
          line-height: 1.6;
        `}

        ${El === "blockquote" &&
        css`
          position: relative;
          margin: 0;
          padding-top: 1rem;
          ${mediaQueries.xs} {
            margin: 0 4ch;
          }
          p {
            color: ${theme.color.quote};
            font-size: 1.2rem;
            ${mediaQueries.xs} {
              font-size: ${fontSizes.md};
            }
            font-style: italic;
          }
          p:not(:first-child) {
            text-indent: 2ch;
            margin-top: 0.5em;
          }
          p::after,
          p::before {
            font-size: ${fontSizes.md};
            ${mediaQueries.xs} {
              font-size: ${fontSizes.xl};
            }
            line-height: 0;
          }
          p:first-child::before {
            content: open-quote;
            margin-right: 0.5ch;
          }
          p:last-child::after {
            content: close-quote;
            margin-left: 0.5ch;
          }
          & + figcaption {
            ${mediaQueries.xs} {
              margin: 0 4ch;
            }

            text-align: right;
            padding-bottom: 1rem;
          }
        `}

        ${El === "h1" &&
        css`
          font-size: ${fontSizes.xl};
          ${mediaQueries.md} {
            font-size: ${fontSizes.xxl};
          }
        `}
        ${El === "h2" &&
        css`
          font-size: ${fontSizes.lg};
          ${mediaQueries.md} {
            font-size: ${fontSizes.xl};
          }
        `}
        ${El === "h3" &&
        css`
          font-size: ${fontSizes.md};
          ${mediaQueries.md} {
            font-size: ${fontSizes.lg};
          }
        `}
        ${El === "h4" &&
        css`
          font-size: ${fontSizes.sm};
          ${mediaQueries.md} {
            font-size: ${fontSizes.md};
          }
        `}
        ${(El === "h5" || El === "h6") &&
        css`
          font-size: ${fontSizes.sm};
        `}

        ${cssProp}
      `}
    >
      {children}
    </El>
  )
}

export default Text
