import { useState } from "react"
import { css, useTheme, keyframes } from "@emotion/react"
import {
  fontSizes,
  colorPrimatives,
  mediaQueries,
  fontFamilies,
} from "../styles/theme"
import { Link } from "gatsby"
import Text from "../styles/typography"
import SearchIcon from "../components/Icons/search"
import { IconButton } from "../components/Button"
import Search from "../components/Search"

const Navigation = () => {
  const theme = useTheme()
  const [isSearchActive, setIsSearchActive] = useState(false)

  const handleClose = () => setIsSearchActive(false)

  return (
    <nav
      css={css`
        position: relative;
        padding-bottom: 1em;
        margin: 0 16px;

        @media (min-width: 842px) {
          margin: 0;
        }
      `}
    >
      <ul
        css={css`
          display: flex;
          justify-content: space-between;
          padding-top: 3px;

          li {
            list-style: none;
            margin-left: 0;
          }
        `}
      >
        <li>
          <Text
            el={Link}
            to="/about"
            css={css`
              font-family: ${fontFamilies.tajawal};
              font-weight: 400;
              text-decoration: none;
              color: ${theme.color.text};

              ${mediaQueries.sm} {
                font-size: ${fontSizes.md};
              }

              &:hover {
                color: ${colorPrimatives.tumbleweed};
                text-decoration: underline;
              }
            `}
          >
            About
          </Text>
        </li>
        <li>
          <IconButton
            icon={
              <SearchIcon
                css={css`
                  ${mediaQueries.sm} {
                    height: ${fontSizes.md};
                  }
                `}
              />
            }
            onClick={() => setIsSearchActive(true)}
          />
        </li>
      </ul>
      {isSearchActive && <Search onClose={handleClose} />}
    </nav>
  )
}

export default Navigation
