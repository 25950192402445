import { css } from "@emotion/react"

/**
 * Breakpoints
 */

export const breakpoints = {
  xs: 500,
  sm: 640,
  md: 768,
  container: 810,
  lg: 1024,
  xl: 1280,
  xxl: 1536,
}

export const mediaQueries = {}

for (const [bp, value] of Object.entries(breakpoints)) {
  mediaQueries[bp] = `@media (min-width: ${value}px)`
}

/**
 * Typography
 */

export const fontSizes = {
  xs: "0.8rem",
  sm: "1rem",
  md: "1.5rem",
  lg: "2rem",
  xl: "2.5rem",
  xxl: "4rem",
}

export const fontFamilies = {
  tajawal: `"Tajawal", Arial, Helvetica, sans-serif`,
  amiri: `"Amiri", Georgia, "Times New Roman", Times, serif`,
}

/**
 * Colors
 */

export const colorPrimatives = {
  white: "#ffffff",
  richBlack: "rgba(5,6,9,1)",
  fieryRose: "#FF6371",
  rustyRed: "#DA3645",
  rubyRed: "#A10A18",
  mistyRose: "#FFEBE5",
  tumbleweed: "#D69F7E",
  vanDykeBrown: "#75422D",
  overlay: "rgba(5,6,9,0.85)",
}

/**
 * Themes
 */

export const defaultTheme = {
  color: {
    background: colorPrimatives.mistyRose,

    text: colorPrimatives.richBlack,
    link: colorPrimatives.rubyRed,
    linkHover: colorPrimatives.richBlack,
    quote: colorPrimatives.vanDykeBrown,

    buttonPrimary: colorPrimatives.rustyRed,
    buttonHoverPrimary: colorPrimatives.rubyRed,
    buttonTextPrimary: colorPrimatives.white,
    buttonTextHoverPrimary: colorPrimatives.mistyRose,

    buttonSecondary: colorPrimatives.fieryRose,
    buttonHoverSecondary: colorPrimatives.mistyRose,
    buttonTextSecondary: colorPrimatives.fieryRose,
    buttonTextHoverSecondary: colorPrimatives.mistyRose,
  },
}

export const darkTheme = {
  color: {
    ...defaultTheme.color,
    background: colorPrimatives.richBlack,

    text: colorPrimatives.mistyRose,
    link: colorPrimatives.fieryRose,
    linkHover: colorPrimatives.mistyRose,
    quote: colorPrimatives.tumbleweed,
  },
}

export const secondaryTheme = {
  color: {
    ...darkTheme.color,
    link: colorPrimatives.white,
    linkHover: colorPrimatives.mistyRose,
    quote: colorPrimatives.white,
  },
}
