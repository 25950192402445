import { css, useTheme, keyframes } from "@emotion/react"
import Text from "../styles/typography"
import { Link } from "gatsby"
import { fontSizes, fontFamilies, breakpoints } from "../styles/theme"
import { StaticImage } from "gatsby-plugin-image"
import Navigation from "./navigation"

const Header = () => {
  const theme = useTheme()

  const slideAnimation = keyframes`
		from {
			transform: translate3d(0,0,0);
		}
		to {
			transform: translate3d(-50%, 0, 0);

		}
	`

  return (
    <header
      css={css`
        background-color: ${theme.color.background};
      `}
    >
      <div
        css={css`
          max-width: ${breakpoints.container}px;
          margin: auto;
          overflow-x: clip;
        `}
      >
        <Link
          alt="EmergentTapestry.com"
          to="/"
          css={css`
            &:active {
              text-decoration: none;
            }
          `}
        >
          <Text
            el="p"
            css={css`
              font-family: ${fontFamilies.tajawal};
              font-size: ${fontSizes.xs};
              font-weight: 400;
              text-align: center;
              max-width: initial;

              display: flex;
              justify-content: space-between;
              position: relative;
              top: 1em;
            `}
          >
            <span>Emergent</span> <span>Tapestry</span>
          </Text>
          <div
            css={css`
              position: relative;
            `}
          >
            <div
              css={css`
                margin: 3% 0;
                width: 200%;
                animation: ${slideAnimation} 30s linear infinite;
              `}
            >
              <StaticImage
                loading="eager"
                src="../images/Header.png"
                css={css`
                  width: 50%;
                `}
              />
              <StaticImage
                loading="eager"
                src="../images/Header.png"
                css={css`
                  width: 50%;
                `}
              />
            </div>

            <StaticImage
              loading="eager"
              src="../images/Logo.png"
              css={css`
                position: absolute;
                top: calc(50%);
                left: 50%;
                width: 25%;
                transform: translate(-50%, -50%);
              `}
            />
          </div>
        </Link>
        <Navigation />
      </div>
    </header>
  )
}

export default Header
