import config from "../../data/SiteConfig"
import { css, useTheme } from "@emotion/react"
import {
  fontSizes,
  fontFamilies,
  breakpoints,
  mediaQueries,
} from "../styles/theme"
import UserLinks from "../components/UserLinks"
import siteConfig from "../../data/SiteConfig"
import Text from "../styles/typography"

const Footer = () => {
  const theme = useTheme()

  return (
    <footer
      css={css`
        max-width: ${breakpoints.container}px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        margin: 16px auto;
        > * {
          margin: 16px 0;
        }
        ${mediaQueries.container} {
          flex-direction: row-reverse;
        }
      `}
    >
      <UserLinks config={siteConfig} labeled />
      <Text
        css={css`
          font-family: ${fontFamilies.tajawal};
          font-size: ${fontSizes.xs};
          text-align: center;
          margin-left: 16px;
          margin-right: 16px;
        `}
      >
        {config.copyright}
      </Text>
    </footer>
  )
}

export default Footer
