import SearchBar from "./SearchBar"
import SearchResults from "./SearchResults"
import { InstantSearch } from "react-instantsearch-dom"
import algoliasearch from "algoliasearch/lite"
import { createRef, useState, useMemo } from "react"
import useClickOutside from "../../utils/useClickOutside"

const searchIndices = [{ name: `Pages`, title: `Pages` }]

const Search = ({ onClose }) => {
  const rootRef = createRef()
  const [query, setQuery] = useState()
  const [hasFocus, setFocus] = useState(false)
  const searchClient = useMemo(
    () =>
      algoliasearch(
        process.env.GATSBY_ALGOLIA_APP_ID,
        process.env.GATSBY_ALGOLIA_SEARCH_KEY
      ),
    []
  )

  useClickOutside(rootRef, () => setFocus(false))

  return (
    <div ref={rootRef}>
      <InstantSearch
        searchClient={searchClient}
        indexName={searchIndices[0].name}
        onSearchStateChange={({ query }) => setQuery(query)}
      >
        <SearchBar
          onClose={onClose}
          onFocus={() => setFocus(true)}
          hasFocus={hasFocus}
        />
        <SearchResults
          onClose={onClose}
          show={query && query.length > 0 && hasFocus}
          indices={searchIndices}
        />
      </InstantSearch>
    </div>
  )
}

export default Search
