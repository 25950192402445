import React from "react"
import { Helmet } from "react-helmet"
import config from "../../data/SiteConfig"
import { defaultTheme, darkTheme } from "../styles/theme"
import GlobalStyles from "../styles/globalStyles"
import Header from "./header"
import Footer from "./footer"
import Main from "./main"
import { ThemeProvider } from "@emotion/react"

export default function MainLayout({ children }) {
  return (
    <ThemeProvider theme={darkTheme}>
      <GlobalStyles />
      <Helmet>
        <meta name="description" content={config.siteDescription} />
        <html lang="en" />
      </Helmet>
      <Header />
      <ThemeProvider theme={defaultTheme}>
        <Main>{children}</Main>
      </ThemeProvider>
      <Footer />
    </ThemeProvider>
  )
}
